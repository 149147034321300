<template>
  <div class="search-history">
    <div class="searchBox">
      <div class="searchLeft">
        <div class="searchInfo">
          <div class="searchLogo" @click="onSearch(null)">
            <img
              class="searchLogoIcon"
              src="~assets/img/client/icon_home_search@2x.png"
            />
          </div>
          <input
            class="searchInput"
            ref="searchTask"
            v-model="searchText"
            @keyup.enter="onSearch(null)"
            :placeholder="$t.meta.search"
          />
        </div>
      </div>
      <div class="searchRight">
        <div class="searchBtn">
          <button class="cancel" @click="backHome">{{ $t.cancel }}</button>
        </div>
      </div>
    </div>
    <div class="historyBox">
      <div class="historyBox-header">
        <div class="box-left">{{ $t.historySearch }}</div>
        <div class="box-right" v-if="searchHistory.length > 0">
          <van-icon name="delete-o" size="20" @click="onClear" />
        </div>
      </div>
      <div class="historyBox-content">
        <div
          class="box-click"
          v-for="(item, index) in searchHistory"
          :key="index"
          @click="onSearch(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon } from "vant";
Vue.use(Icon);

export default {
  name: "SearchHistory",
  components: {},
  data() {
    return {
      searchText: "",
      searchHistory: [],
    };
  },
  mounted() {
    this.$refs.searchTask.focus();
    var search = JSON.parse(localStorage.getItem("searchHistory"));
    if (search) this.searchHistory = search;
  },
  methods: {
    onClear() {
      this.searchHistory = [];
      localStorage.setItem("searchHistory", null);
    },
    onSearch(text) {
      var searchText;
      if (text) {
        searchText = text;
      } else {
        searchText = this.searchText;
      }

      if (!searchText) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.cStore.pleaseEnterContent,
        });
        return;
      }

      if (!text) {
        this.searchHistory.push(this.searchText);
        localStorage.setItem(
          "searchHistory",
          JSON.stringify(this.searchHistory)
        );
      }

      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "SreachValues",
            value: searchText,
          },
        ],
      });
    },
    backHome() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";

.search-history {
  width: 100%;
  .searchBox {
    z-index: 10;
    width: 100%;
    padding: 3% 0%;
    background: linear-gradient(left, #71d283, #01aaa3);
    .searchLeft {
      width: 80%;
      display: inline-block;
      .searchInfo {
        margin-left: 5%;
        padding: 0.3rem 0.5rem;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 10px;
        background-color: #ffffff;
        position: relative;
        .searchInput {
          border: 0;
          display: inline-block;
          vertical-align: middle;
          margin-left: 2%;
          line-height: 25px;
          background-color: #ffffff;
          color: #a7a7a7;
          font-size: 14px;
          width: 80%;
        }
        .searchLogo {
          height: 21px;
          display: inline-block;
          vertical-align: middle;
          .searchLogoIcon {
            width: 21px;
            height: 21px;
            object-fit: cover;
            -webkit-object-fit: cover;
          }
        }
      }
    }
    .searchRight {
      width: 20%;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      .searchBtn {
        .cancel {
          border: 0;
          background: transparent;
          color: #fff;
        }
      }
    }
  }
  .historyBox {
    padding: 5%;
    font-size: 0.4rem;
    .historyBox-header {
      .box-left {
        font-size: 1rem;
        width: 50%;
        display: inline-block;
        vertical-align: middle;
      }
      .box-right {
        width: 50%;
        font-size: 0.4rem;
        display: inline-block;
        text-align: right;
        vertical-align: middle;
      }
    }
    .historyBox-content {
      margin: 2% 0%;
      .box-click {
        display: inline-block;
        padding: 2%;
        background: #e5e5e5;
        border-radius: 5px;
        margin-top: 3%;
        margin-right: 3%;
        font-size: 14px;
      }
    }
  }
}
</style>
